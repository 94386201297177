<template>
  <div id="wizard-accounts-wrapper">
    <div class="mb-4 row">
      <!-- Add Button -->
      <div class="gap-2 mb-5 d-flex align-items-center">
        <h3 class="mb-0 me-auto">{{ $t('talentWizard.businessAccounts') }}</h3>
        <button
          type="button"
          class="btn add-btn-not-dashed float-end wizard-btn-actions"
          @click="addLink"
          :disabled="checkNumberOfWebsites()"
        >
          <i class="bi bi-plus-circle"></i>
          <span>
            {{ $t('talentWizard.addLink') }}
          </span>
        </button>
      </div>
      <!-- ----------- -->

      <div class="mb-3 col-12" v-for="(link, index) in linksInputs" :key="'link-' + index">
        <h6 class="mb-2">{{ link.label }}</h6>
        <div class="input-group flex-nowrap">
          <span class="input-group-text" :class="isRTL ? '' : 'rounded-start-2 rounded-end-0'" id="addon-wrapping">
            <img loading="lazy"
            :src="require('../../assets/images/' + link.img)"
            width="30" alt />
          </span>
          <input
            type="text"
            class="form-control"
            :class="isRTL ? '' : 'rounded-start-0 rounded-end-2'"
            v-model="$v.links[link.model].$model"
            :placeholder="link.placeHolder"
            @input="(e) => handleLinkChange(e, link.model)"
          />
        </div>

        <!-- ********* Validation Error*********** -->
        <div class="my-2">
           <span v-if="isValidUrl[`linkIndex_${link.model}`] == false" class="error"
            >
                        {{ $t('talentWizard.invalidlink') }}

          </span>
          <!-- <VulidateError :validationField="$v.links[link.model]" /> -->
        </div>
        <!-- ************************************* -->
      </div>

      <div
        class="mb-3 col-12"
        v-for="(website, index) in $v.links.websites.$each.$iter"
        :key="index"
      >
        <h6 class="mb-2">website link</h6>
        <div class="form-group row position-relative">
          <div class="input-group flex-nowrap">
            <span class="input-group-text" :class="isRTL ? '' : 'rounded-start-2 rounded-end-0'" id="addon-wrapping">
              <img loading="lazy" src="../../assets/images/website.svg" width="30" alt />
            </span>
            <input
              v-model="website.url.$model"
              type="text"
              class="form-control fix-margin-right rounded-start-0 rounded-end-0 border-end-0"
              placeholder="e.g: www.website/username"
              @input="(e) => handleLinkChange(e, index)"
            />
            <button type="button" class="btn border rounded-end d-flex justify-content-center align-items-center" :class="
                isRTL
                  ? 'rounded-start-2 rounded-end-0 border-end-0'
                  : 'rounded-start-0 rounded-end-2'
              " @click="deleteLink(index)">
              <img loading="lazy" src="../../assets/images/ic_delete_3.png" alt />
            </button>
          </div>
          <!-- ********* Validation Error*********** -->
          <div class="my-2">
             <span v-if="isValidUrl[`linkIndex_${index}`] == false" class="error"
            >
              {{ $t('talentWizard.invalidlink') }}
          </span>
            <!-- <VulidateError :validationField="website.url" :params="['urlFormat']"/> -->
          </div>
          <!-- ************************************* -->
        </div>
      </div>
      <!-- ------------ -->
      <div class="mt-5 mb-2">
        <NextPrevBtn
          :className="'d-grid gap-2 d-flex justify-content-md-end'"
          :step="stepNum"
          :hasError="$v.$invalid || !handleLinkValidate()"
          :formData="links"
          :label="$t('talentWizard.finish')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NextPrevBtn from "../NextPrevWizardBtn/NextPrevWizardBtn.vue";
import ACCOUNTS from "../../enums/UserAccounts";
// import { helpers } from "vuelidate/lib/validators";
import { deepUrlValidation } from "../../utils/utils"
// import VulidateError from "../shared/VulidateError/vulidateError";
// const urlFormat = helpers.regex(
//   "urlFormat",
//   /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
// );
import "./accounts.scss"
import rtlMixin from '../../mixins/rtlMixin';
export default {
  props: ["stepNum"],
  components: { NextPrevBtn },
  mixins:[rtlMixin],
  data() {
    return {
      linksInputs: this.getLinkInputs(),
      isValidUrl: {},
      links: {
        linkedIn: "",
        gitHub: "",
        behance: "",
        dribbble: "",
        websites: [{ url: "" }]
      }
    };
  },
  validations: {
    links: {
      linkedIn: {},
      gitHub: {},
      behance: {},
      dribbble: {},
      websites: {
        $each: { url: {} }
      }
    }
  },
  mounted() {
    this.getAccounts();
  },
  methods: {
    addLink() {
      this.links.websites.push({ url: "" });
    },
    checkNumberOfWebsites() {
      return this.links.websites.length == 10;
    },
    deleteLink(index) {
      this.links.websites.splice(index, 1);
    },
    async getAccounts() {
      try {
        await this.axios.get("/api/accounts").then(res => {
          res.data.data.forEach(account => {
            if (account.type != ACCOUNTS.WEBSITE) {
              let receivedLinks = {
                [ACCOUNTS.LINKED_IN]: "linkedIn",
                [ACCOUNTS.GITHUB]: "gitHub",
                [ACCOUNTS.BEHANCE]: "behance",
                [ACCOUNTS.DRIBBBLE]: "dribbble"
              };
              this.links[receivedLinks[account.type]] = account.url;
            } else {
              this.links.websites[0].url != ""
                ? this.links.websites.push({ url: account.url })
                : (this.links.websites[0].url = account.url);
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    getLinkInputs() {
      return [
        {
          label: "Linkedin link",
          placeHolder: "e.g: www.linkedin/username",
          img: "ic-linkedin.svg",
          model: "linkedIn"
        },
        {
          label: "Dribbble link",
          placeHolder: "e.g: www.dribbble/username",
          img: "dribbble.svg",
          model: "dribbble"
        },
        {
          label: "Behance link",
          placeHolder: "e.g: www.behance/username",
          img: "behance.svg",
          model: "behance"
        },
        {
          label: "Github link",
          placeHolder: "e.g: www.Github/username",
          img: "Github.svg",
          model: "gitHub"
        }
      ];
    },
    handleLinkValidate() {
      if (Object.entries(this.isValidUrl)?.length > 0) {
        let isValid;
        if(Object.values(this.isValidUrl)?.includes(false)){
          isValid = false;
        }else{
          isValid = true;
        }
        return isValid;
      } else {
        return true;
      }
    },
      handleLinkChange(e, index) {
      if (e.target.value == "") {
        this.isValidUrl[`linkIndex_${index}`] = true;
        this.handleLinkValidate();
      } else {
       const enteredValue=(e.target.value.startsWith("https://")||e.target.value.startsWith("http://"))?e.target.value :`https://${e.target.value}`
        this.isValidUrl[`linkIndex_${index}`] = !deepUrlValidation(
         enteredValue
        );
        this.handleLinkValidate();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.wizard-accounts-wrapper {
  .input-group-text {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.form-control {
  border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

& .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
      border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
& .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
}
</style>
