export default {
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
    isRTL() {
      const rtlLanguages = ["ar"];
      return rtlLanguages.includes(this.currentLanguage);
    },
  },
};
