<template>
  <div class="talent-wizard-projects">
    <h3>{{ $t("talentWizard.projects") }}</h3>
    <div
      v-for="(project, index) in $v.projects.$each.$iter"
      :key="index"
      class="mb-4 row"
    >
      <div class="mb-4 col-12">
        <div class="d-flex justify-content-end remove-button">
          <button
            type="button"
            class="btn remove-btn wizard-btn-actions d-flex align-items-center"
            @click="deleteProject(index)"
          >
            <i class="flaticon-trash d-flex align-items-center tw-me-2 tw-mr-0"></i>
            <span>{{ $t("talentWizard.remove") }}</span>
          </button>
        </div>
      </div>
      <div class="mb-4 col-sm-6 col-12">
        <label class="form-label required">
          {{ $t("talentWizard.projectName") }}
        </label>
        <input
          v-model="project.name.$model"
          type="text"
          class="form-control"
          :placeholder="$t('talentWizard.projectName')"
        />
        <!-- ********* Validation Error*********** -->
        <div class="mt-1">
          <VulidateError
            :validationField="project.name"
            :params="[
              'required',
              'alphaNumericWithSpecialChars',
              'alphaNumericWithSpecialCharsProjectName',
              { maxLength: { number: '100' } },
            ]"
          />
        </div>
        <!-- ************************************* -->
      </div>

      <div class="mb-4 col-sm-6 col-12">
        <label class="form-label required">
          {{ $t("talentWizard.projectsType") }}
        </label>
        <v-select
          v-model="project.project_type_id.$model"
          :reduce="(name) => name.id"
          class="style-chooser"
          :placeholder="$t('talentWizard.selectProjectType')"
          :options="projectTypesOptions"
          label="name"
        >
          <div slot="no-options">
            {{ $t("talent.sorryNoMatchingOptions") }}
          </div>
        </v-select>
        <!-- ********* Validation Error*********** -->
        <div class="error-select-validation-message">
          <VulidateError
            :validationField="project.project_type_id"
            :params="[]"
          />
        </div>
        <!-- ************************************* -->
      </div>
      <div class="mb-4 col-12">
        <label class="form-label">
          {{ $t("talentWizard.projectLink") }}
        </label>
        <input
          v-model="project.link.$model"
          type="text"
          class="form-control"
          placeholder="https://projectName.com"
          @input="(e) => handleLinkChange(e, index)"
        />
        <!-- ********* Validation Error*********** -->
        <div class="mt-1">
          <span v-if="isValidUrl[`linkIndex_${index}`] == false" class="error">
            {{ $t("talentWizard.invalidlink") }} 
          </span>
          <VulidateError
            :validationField="project.link"
            :params="[ { maxLength: { number: '2000' } }]"
          />
        </div>
        <!-- ************************************* -->
      </div>

      <div class="col-12">
        <div class="my-3 border-top"></div>
      </div>
    </div>
    <!-- Add Project Button -->
    <div class="mb-4 row">
      <div class="col-12">
        <div
          class="gap-2 mt-1 mb-5 d-flex justify-content-end remove-add-project-btn"
        >
          <button
            type="button"
            class="btn add-btn wizard-btn-actions"
            @click="addProject"
            :disabled="checkNumberOfProjects()"
          >
            <i class="bi bi-plus-circle"></i>
            <span>
              {{ $t("talentWizard.addProject") }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="mt-5 mb-2">
      <NextPrevBtn
        :className="'d-grid gap-2 d-flex justify-content-md-end'"
        :step="stepNum"
        :hasError="$v.$invalid || !handleLinkValidate()"
        :isNextDisabled="true"
        :formData="{ projects: projects }"
        :projectTypesOptions="projectTypesOptions"
      />
    </div>
  </div>
</template>

<script>
import {
  deepUrlValidation,
  alphaNumericWithSpecialChars,
  alphaNumericWithSpecialCharsProjectName
} from "../../utils/utils";
import NextPrevBtn from "../NextPrevWizardBtn/NextPrevWizardBtn";
import VulidateError from "../shared/VulidateError/vulidateError";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  props: ["stepNum"],
  components: { NextPrevBtn, VulidateError },
  data() {
    return {
      isValidUrl: {},
      projectTypesOptions: [],
      projects: [
        {
          name: "",
          project_type_id: "",
          link: "",
        },
      ],
    };
  },
  validations: {
    projects: {
      $each: {
        name: {
          required,
          maxLength: maxLength(100),
          alphaNumericWithSpecialChars,
          alphaNumericWithSpecialCharsProjectName
        },
        project_type_id: {
          required,
        },
        link: {
          maxLength: maxLength(2000),
        },
      },
    },
  },
  mounted() {
    this.getProjectTypes();
    this.getUserProjects();
  },
  methods: {
    addProject() {
      this.projects.push({
        name: "",
        project_type_id: "",
        link: "",
      });
    },
    deleteProject(index) {
      this.projects.splice(index, 1);
    },
    checkNumberOfProjects() {
      return this.projects.length == 25;
    },
    async getProjectTypes() {
      try {
        await this.axios.get("/api/staticContent/projectTypes").then((res) => {
          this.projectTypesOptions = res.data.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getUserProjects() {
      try {
        await this.axios.get("/api/projects").then((res) => {
          res.data.data.map((item, index) => {
            if (index + 1 <= this.projects.length) {
              this.projects[index].name = item.name;
              this.projects[index].project_type_id = item.project_type.id;
              this.projects[index].link = item.link;
            } else {
              this.projects.push({
                name: item.name,
                project_type_id:  item.project_type.id,
                link: item.link,
            
              });
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleLinkChange(e, index) {
      if (e.target.value == "") {
        this.isValidUrl[`linkIndex_${index}`] = true;
        this.handleLinkValidate();
      } else {
        const enteredValue =
          e.target.value.startsWith("https://") ||
          e.target.value.startsWith("http://")
            ? e.target.value
            : `https://${e.target.value}`;
        this.isValidUrl[`linkIndex_${index}`] =
          !deepUrlValidation(enteredValue);
        this.handleLinkValidate();
      }
    },
    handleLinkValidate() {
      if (Object.entries(this.isValidUrl)?.length > 0) {
        let isValid;
        if (Object.values(this.isValidUrl)?.includes(false)) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      } else {
        return true;
      }
    },
  },
};
</script>

<style src="./Projects.css"></style>
